import { Suspense, useEffect, useRef, useState } from 'react'
import { LoadingMessage } from './LoadingMessage'
import { Image } from './Image'
import { Vec3 } from './Types'

export const MultiResolutionImage = ({
  lowResSrc,
  highResSrc,
  position = [0, 0, 0],
  scale = [1, 1, 1]
}: {
  lowResSrc: string
  highResSrc: string
  isCameraClose: boolean
  position?: Vec3
  scale?: Vec3
}) => {
  const [swapped, setSwapped] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const handleCloseToObject = (e: CustomEvent) => {
      // @ts-ignore
      if (ref.current && e.detail === ref.current.uuid) {
        // console.log("close to", e.detail);
        setSwapped(true)
      }
    }

    // @ts-ignore
    window.addEventListener('closeToObject', handleCloseToObject)

    // @ts-ignore
    return () => window.removeEventListener('closeToObject', handleCloseToObject)
  }, [])

  return (
    <group ref={ref}>
      <LoadingMessage>
        <Image src={lowResSrc} scale={scale} />
      </LoadingMessage>
      {swapped && (
        <Suspense fallback="">
          <Image src={highResSrc} scale={scale} />
        </Suspense>
      )}
    </group>
  )
}
