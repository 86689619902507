import { useRef, useState } from "react"
import { useSpring } from "@react-spring/core"
import { Vec3 } from "./Types"
// @ts-ignore
import { a } from '@react-spring/three'
import { useThree } from "@react-three/fiber"

export const HoverEffect = ({
  active,
  position = [0, 0, 0],
  children,
}: {
  active: boolean
  position: Vec3
  children: any
}) => {

  const ref = useRef()
  const [hovered, setHovered] = useState(false)

  const { pos } = useSpring({
    pos: hovered || active ? [position[0], position[1], position[2] + 0.25] : position,
    config: { mass: 1, tension: 500, friction: 150, precision: 0.0001 }
  })

  const { gl } = useThree();

  return (
    <a.group
      ref={ref}
      position={pos}
      onPointerOver={(e: any) => {
        e.stopPropagation()
        setHovered(true)
        gl.domElement.classList.add('-pointing');
      }}
      onPointerOut={() => {
        setHovered(false)
        gl.domElement.classList.remove('-pointing');
      }}
      onPointerMissed={() => setHovered(false)}>
      {children}
    </a.group>
  )
}
