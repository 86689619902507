import { forwardRef, useMemo, useImperativeHandle } from 'react'
// @ts-ignore
import * as THREE from 'three'
// @ts-ignore
import { Camera } from 'three'
import { useThree, useFrame, ReactThreeFiber } from '@react-three/fiber'
import CameraControls from 'camera-controls'
// @ts-ignore
// import throttle from 'lodash.throttle'

export type CameraControlsProps = ReactThreeFiber.Overwrite<
  ReactThreeFiber.Object3DNode<CameraControls, typeof CameraControls>,
  {
    target?: ReactThreeFiber.Vector3
    camera?: THREE.Camera
    domElement?: HTMLElement
  }
>

const useCameraControls = (camera: Camera, domElement: HTMLElement, rotateDisabled: boolean) => {
  return useMemo(() => {
    CameraControls.install({ THREE: THREE })
    const controls = new CameraControls(camera, domElement)
    controls.mouseButtons.left = CameraControls.ACTION.TRUCK
    rotateDisabled ? controls.mouseButtons.right = CameraControls.ACTION.NONE : controls.mouseButtons.right = CameraControls.ACTION.ROTATE

    controls.dampingFactor = 0.05;

    // @ts-ignore
    controls.setCameraPosition = (position) => {
    //if (controlsRef.current) {
      controls.setPosition(position[0], position[1], position[2], true)
      controls.setTarget(position[0], position[1], 0, true)
    //}
    }

    // @ts-ignore
    // controls.truckUp = () => {
    //   console.log(controls)
    //   const z = controls.getPosition().z
    //   controls.truck(0, (z / 2) * -1, true)
    // }

    controls.addEventListener('controlstart', () => {
      switch (controls.currentAction) {
        case CameraControls.ACTION.ROTATE:
        case CameraControls.ACTION.TOUCH_ROTATE: {
          domElement.classList.add('-dragging');
          break;
        }
        case CameraControls.ACTION.TRUCK:
        case CameraControls.ACTION.TOUCH_TRUCK: {
          domElement.classList.add('-moving');
          break;
        }
        case CameraControls.ACTION.DOLLY:
        case CameraControls.ACTION.ZOOM: {
          domElement.classList.add('-zoomIn');
          break;
        }
        case CameraControls.ACTION.TOUCH_DOLLY_TRUCK:
        case CameraControls.ACTION.TOUCH_ZOOM_TRUCK: {
          domElement.classList.add('-moving');
          break;
        }
        default: {
          break;
        }
      }
    });
    controls.addEventListener('controlend', () => {
      domElement.classList.remove(
        '-dragging',
        '-moving',
        '-zoomIn'
      );
    });
    return controls
  }, [camera, domElement, rotateDisabled])
}

const ExtendedCameraControls = (
  {
    camera,
    domElement,
    rotateDisabled,
    onWake,
    onSleep,
    onChange
  }: {
    camera: Camera
    domElement: any
    rotateDisabled: boolean
    onWake: () => void
    onSleep: () => void
    onChange: (e: any) => void
  },
  ref: ((instance: CameraControls) => void) | React.RefObject<CameraControls> | null | undefined
) => {
  const defaultCamera = useThree(({ camera }) => camera)
  const gl = useThree(({ gl }) => gl)
  //const explCamera = camera || defaultCamera
  const explCamera = defaultCamera // todo: passing a cameraRef doesn't work
  const explDomElement = domElement || gl.domElement
  const controls = useCameraControls(explCamera, explDomElement, rotateDisabled)
  useImperativeHandle(ref, () => controls)

  useFrame((_state, delta) => {
    controls.update(delta)
    // console.log(controls.getFocalOffset())
  })

  // const handleWake = throttle(() => {
  //   onWake()
  // }, 1000)

  // const handleSleep = throttle(() => {
  //   onSleep()
  // }, 1000)

  // controls.addEventListener('wake', handleWake)
  // controls.addEventListener('sleep', handleSleep)

  return <primitive ref={ref} object={controls} />
}

export default forwardRef(ExtendedCameraControls)
