/* eslint-disable */
// @ts-nocheck
import { useRef, useMemo } from 'react'
import { createAsset } from 'use-asset'
import { Text } from '@react-three/drei'
import { Canvas, LanguageMap, loadManifest, parseManifest } from 'manifesto.js'
import { truncate, truncateAtLineBreak } from './Utils'
import { Vec3 } from './Types'
import { CanvasWorld } from './CanvasWorld'
import { ViewingDirection } from '@iiif/vocabulary'
import { a } from '@react-spring/three'
import { MultiResolutionImage } from './MultiResolutionImage'
import { HoverEffect } from './HoverEffect'
import useStore from './Store'

const useCanvas = (canvas, scale, margin, lowResWidth = 300, highResWidth = 900) => {
  return useMemo(() => {
    let images = canvas.getImages()
    let imgSrc
    //if (images.length) {
    const firstImage = images[0]
    const resource = firstImage.getResource()
    imgSrc = resource.id
    // } else  {
    //   // iiif v3
    //   images = canvas.getContent();
    //   const firstImage = images[0];
    //   const body = firstImage.getBody();
    //   imgSrc = body[0].id;
    // }

    const lowResImageSrc = imgSrc.replace('full/full', `full/${lowResWidth},`)
    const highResImageSrc = imgSrc.replace('full/full', `full/${highResWidth},`)

    const label: string | null = LanguageMap.getValue(canvas.getLabel())
    const labelX = (scale[0] / 2) * -1
    const labelY: number = (scale[1] / 2) * -1 - margin[2]
    const labelPos = [labelX, labelY, 0]
    const id = canvas.id

    return {
      lowResImageSrc,
      highResImageSrc,
      label,
      labelPos,
      id
    }
  }, [canvas, scale, margin])
}

const IIIFCanvas = ({ canvas, position, scale, margin }: { canvas: Canvas; position: Vec3; scale: Vec3; margin: Margin }) => {
  const { selectedObject, setSelectedObject } = useStore()

  const ref = useRef()

  const { lowResImageSrc, highResImageSrc, label, labelPos, id } = useCanvas(canvas, scale, margin, 300, 900)

  return (
    <HoverEffect active={selectedObject === id}>
      <a.group
        ref={ref}
        position={position}
        userData={{
          id,
          prevId: canvas.prevCanvas?.id,
          nextId: canvas.nextCanvas?.id
        }}
        onPointerDown={(e) => {
          e.stopPropagation()
          // todo: should use canvas id (url), not object uuid?
          // setSelectedObject(ref.current.uuid)
          setSelectedObject(id)
        }}>
        <MultiResolutionImage lowResSrc={lowResImageSrc} highResSrc={highResImageSrc} scale={scale} />
        {label && (
          <>
            <Text fontSize={0.3} color="#ffffff" anchorY="top" position={labelPos} maxWidth={10} anchorX="left">
              {truncate(label, 30)}
            </Text>
            {/* <Text fontSize={0.3} color="#ffffff" anchorY="top" position={[labelX, labelY - 0.5, 0]} maxWidth={10} anchorX="left">
              selected: {state.selectedObject === ref.current.uuid ? "true" : "false"} close: {state.closeToObject === ref.current.uuid ? "true" : "false"}
            </Text> */}
          </>
        )}
      </a.group>
    </HoverEffect>
  )
}

const asset = createAsset(async (url) => {
  return await loadManifest(url)
})

const useIIIFManifest = (url) => {
  return useMemo(() => {
    const readAsset = asset.read(url)
    const manifest = parseManifest(readAsset)
    const sequence = manifest.getSequenceByIndex(0)
    const paged = manifest.isPagingEnabled()
    const canvases = sequence.getCanvases()
    const canvasWorld = new CanvasWorld(canvases, [], ViewingDirection.LEFT_TO_RIGHT)
    return {
      manifest,
      paged,
      canvases,
      canvasWorld
    }
  }, [url])
}

export const IIIFManifest = ({
  url,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  spacing = 1,
  margin = [0, 0, 0]
}: {
  url: string
  position: Vec3
  scale: Vec3
  spacing: number
}) => {
  const { manifest, paged, canvases, canvasWorld } = useIIIFManifest(url)

  const Layout = () => {
    let xPos = 0

    return useMemo(() => {
      // console.log(`render IIIF Canvases ${url}`)
      return canvases.map((canvas, idx) => {
        const contentResource = canvasWorld.contentResource(canvas.imageServiceIds[0])
        const canvasBounds = canvasWorld.contentResourceToWorldCoordinates(contentResource)

        const w = canvasBounds[2] * scale[0]
        const h = canvasBounds[3] * scale[1]
        const x = position[0] + xPos + w / 2 + margin[3]
        const y = position[1] - h / 2 - margin[0]
        const z = position[2]

        let separator = 0

        if (paged) {
          separator = (idx - 1) % 2 === 0 ? 0 : spacing
        } else {
          separator = spacing
        }

        xPos = x + w / 2 + separator

        const p = [x, y, z]
        const s = [w, h, 0.1]

        if (idx > 0) {
          canvas.prevCanvas = canvases[idx - 1]
        }

        if (idx < canvases.length - 1) {
          canvas.nextCanvas = canvases[idx + 1]
        }

        return <IIIFCanvas key={`canvas-${idx}`} canvas={canvas} position={p} scale={s} margin={margin} />
      })
    }, [canvases])
  }

  return (
    <>
      {useMemo(() => {
        return manifest ? (
          <>
            <Text
              fontSize={0.5}
              color="#ffffff"
              anchorY="bottom"
              position={[position[0], position[1], position[2]]}
              maxWidth={10}
              anchorX="left">
              {truncateAtLineBreak(LanguageMap.getValue(manifest.getLabel()))}
            </Text>
            <Layout />
          </>
        ) : null
      }, [manifest])}
    </>
  )
}
