/* eslint-disable */
// @ts-nocheck
import React, { useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { Vec3 } from './Types'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'

export const GLTF = ({
  src,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  autoRotate,
}: {
  src: string
  position: Vec3
  scale: Vec3
  autoRotate: boolean
}) => {
  const { scene } = useGLTF(src)
  const ref = useRef()
  const modelRef = useRef()

  useFrame((_state) => {
    if (modelRef.current && autoRotate) {
      modelRef.current.rotation.y += 0.01
    }
  })

  return (
    <a.group
      ref={ref}
      position={position}>
      <primitive ref={modelRef} object={scene} scale={scale} />
      <pointLight intensity={2} position={[scale[0] / 2, scale[1] / 2, 2.5]} />
    </a.group>
  )
}
