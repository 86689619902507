import { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
// @ts-ignore
import { Vector2 } from 'three'
// @ts-ignore
import throttle from 'lodash.throttle'

export const ProximityDetector = ({
  closeDistance = 10,
  throttleMS = 500,
  disabled
}: {
  closeDistance: number
  throttleMS: number
  disabled: boolean
}) => {
  const intersectingRef = useRef<undefined | any>()
  const closeToObjectRef = useRef<undefined | string>()

  const raycast = throttle((state: any) => {
    if (disabled) {
      return
    }
    // console.log("raycast")
    state.raycaster.setFromCamera(new Vector2(), state.camera)

    const intersects = state.raycaster.intersectObjects(state.scene.children, true)

    if (intersects.length > 0) {
      const uuid: string = intersects[0].object.parent.uuid
      if (
        !intersectingRef.current ||
        intersectingRef.current.object.uuid !== uuid ||
        intersectingRef.current.distance !== intersects[0].distance
      ) {
        intersectingRef.current = intersects[0]
        if (intersectingRef.current.distance <= closeDistance && closeToObjectRef.current !== uuid) {
          const event = new CustomEvent('closeToObject', { detail: uuid })
          window.dispatchEvent(event)
          closeToObjectRef.current = uuid
        }
      }
    }
  }, throttleMS)

  useFrame(raycast)

  return <></>
}
