// @ts-nocheck
import { Suspense } from 'react'
import { Text } from '@react-three/drei'
import { Vec3 } from './Types'

export const LoadingMessage = ({ text, position = [0, 0, 0], children }: {
  text?: string | undefined
  position?: Vec3
  children: any
}) => {
  return (
    <Suspense
      fallback={
        <Text fontSize={0.5} color="#ffffff" position={position}>
          { text || "loading..." }
        </Text>
      }>
      {children}
    </Suspense>
  )
}