/* eslint-disable */
// @ts-nocheck
import { useEffect, useRef } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { PerspectiveCamera } from '@react-three/drei'
import ExtendedCameraControls from './ExtendedCameraControls'
import { IIIFManifest } from './IIIFManifest'
import { GLTF } from './GLTF'
import './styles.css'
import { Video } from './Video'
// import { Image } from './Image'
import { ProximityDetector } from './ProximityDetector'
import { LoadingMessage } from './LoadingMessage'
import { HoverEffect } from './HoverEffect'
import useStore from './Store'
import { getObjectByUserDataProperty } from './Utils'
import { useKeyPress } from './Hooks'
import { EffectComposer, Bloom, Vignette } from '@react-three/postprocessing'

function SceneNavigator({ controls, initialCameraPosition }) {
  const upPress: boolean = useKeyPress('ArrowUp')
  const downPress: boolean = useKeyPress('ArrowDown')
  const leftPress: boolean = useKeyPress('ArrowLeft')
  const rightPress: boolean = useKeyPress('ArrowRight')
  const escapePress: boolean = useKeyPress('Escape')
  const { scene } = useThree()
  const { selectedObject, setSelectedObject, prevObject, setPrevObject, nextObject, setNextObject, zoomIncludesLabels } = useStore()

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("angle")
  //     controls.current.polarAngle = 45
  //   }, 3000);
  // }, [])

  useEffect(() => {
    if (controls) {
      if (selectedObject) {
        // object selected, prev and next
        if (leftPress && prevObject) {
          setSelectedObject(prevObject)
        }
        if (rightPress && nextObject) {
          setSelectedObject(nextObject)
        }
        if (escapePress && selectedObject) {
          controls.setPosition(initialCameraPosition[0], initialCameraPosition[1], initialCameraPosition[2], true)
          controls.setTarget(initialCameraPosition[0], initialCameraPosition[1], 0, true)
          setSelectedObject(null)
        }
      } else {
        // nothing selected, free explore
        const { z } = controls.getPosition()

        if (upPress) {
          // truck up
          controls.truck(0, (z / 2) * -1, true)
        }
        if (downPress) {
          // truck down
          controls.truck(0, z / 2, true)
        }
        if (leftPress) {
          // truck left
          controls.truck((z / 2) * -1, 0, true)
        }
        if (rightPress) {
          // truck right
          controls.truck(z / 2, 0, true)
        }
      }
    }
  }, [controls, upPress, downPress, leftPress, rightPress, escapePress])

  function getObjectById(id: string) {
    if (zoomIncludesLabels) {
      return getObjectByUserDataProperty(scene, 'id', id)
    }

    return getObjectByUserDataProperty(scene, 'id', id)?.children[0]
  }

  useEffect(() => {
    if (selectedObject && controls) {
      const current = getObjectById(selectedObject)

      if (current) {
        setTimeout(() => {
          controls.fitToBox(current, true.valueOf, {
            paddingLeft: 0.25,
            paddingRight: 0.25,
            paddingBottom: 0.25,
            paddingTop: 0.25
          })
        }, 0)
      }

      let prevId

      if (zoomIncludesLabels) {
        prevId = current.userData.prevId || null
      } else {
        prevId = current.parent.userData.prevId || null
      }

      setPrevObject(prevId)

      let nextId

      if (zoomIncludesLabels) {
        nextId = current.userData.nextId || null
      } else {
        nextId = current.parent.userData.nextId || null
      }

      setNextObject(nextId)
    }
  }, [selectedObject])

  return <></>
}

function Header({ controls, initialCameraPosition }) {
  const {
    animating,
    nextObject,
    prevObject,
    rotateDisabled,
    selectedObject,
    setRotateDisabled,
    setSelectedObject,
    setZoomIncludesLabels,
    zoomIncludesLabels,
  } = useStore()

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          color: '#fff',
          padding: '1.5rem'
        }}>
        <img
          src="/svg/logo.svg"
          style={{
            width: '40px'
          }}
          title="Infinite Canvas"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          color: '#fff',
          padding: '1.5rem'
        }}>
        by{' '}
        <a
          href="https://twitter.com/edsilv"
          target="_blank"
          style={{
            color: 'white'
          }}>
          @edsilv
        </a>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: '1rem'
        }}>
        <button
          style={{
            margin: '0 1rem 0 0'
          }}
          onClick={() => {
            controls.setPosition(initialCameraPosition[0], initialCameraPosition[1], initialCameraPosition[2], true)
            controls.setTarget(initialCameraPosition[0], initialCameraPosition[1], 0, true)
            setSelectedObject(null)
          }}>
          Reset
        </button>
        {prevObject && (
          <button
            style={{
              margin: `0 ${nextObject ? '0' : '1rem'} 0 0`
            }}
            onClick={() => {
              setSelectedObject(prevObject)
            }}>
            &lt;
          </button>
        )}
        {nextObject && (
          <button
            style={{
              margin: '0 1rem 0 0'
            }}
            onClick={() => {
              setSelectedObject(nextObject)
            }}>
            &gt;
          </button>
        )}
        <button
          style={{
            margin: '0 0 0 0'
          }}
          onClick={() => {
            const { z } = controls.getPosition()
            controls.dollyTo((z / 2), true)
          }}>
          +
        </button>
        <button
          style={{
            margin: '0 1rem 0 0'
          }}
          onClick={() => {
            const { z } = controls.getPosition()
            controls.dollyTo((z * 2), true)
          }}>
          -
        </button>
        <button
          onClick={() => {
            const position = [1.0161428973265978, 5.683345423447621, 6.225515399543609]
            controls.setPosition(position[0], position[1], position[2], true)
            controls.setTarget(position[0], position[1], 0, true)
          }}>
          Astronaut
        </button>
        <button
          onClick={() => {
            const position = [6.959352088638021, 5.618132916204495, 5.1850730909755125]
            controls.setPosition(position[0], position[1], position[2], true)
            controls.setTarget(position[0], position[1], 0, true)
          }}>
          One Small Step (video)
        </button>
        <button
          onClick={() => {
            setSelectedObject('https://media.nga.gov/public/manifests/canvas/1236.json')
          }}>
          Vermeer
        </button>
        <button
          onClick={() => {
            setSelectedObject('http://iiif.vam.ac.uk/iiif/MSL:1876:Forster:141:II/canvas/c182')
          }}>
          Perpetual Motion Machine
        </button>
        {/* <button
          onClick={() => {
            setSelectedObject('https://gallica.bnf.fr/iiif/ark:/12148/btv1b10509612q/canvas/f79')
          }}>
          Alchemy
        </button> */}
        {/* <div
          style={{
            padding: '0.5rem 0 0 0'
          }}>
          Selected: {selectedObject}
        </div> */}
        {/* <div>animating: {animating ? 'true' : 'false'}</div> */}
        {/* <label
          title="Use mouse right click"
          style={{
            color: '#fff',
            margin: '0 0 0 1rem'
          }}>
          Rotate Enabled&nbsp;
          <input
            type="checkbox"
            checked={!rotateDisabled}
            onChange={(e) => {
              setRotateDisabled(!rotateDisabled)
            }}
          />
        </label> */}
        {/* <label
          title="Zoom includes labels"
          style={{
            color: '#fff',
            padding: '0.5rem 0 0 0',
            display: 'block'
          }}>
          <input
            type="checkbox"
            checked={zoomIncludesLabels}
            onChange={(e) => {
              setZoomIncludesLabels(!zoomIncludesLabels)
            }}
          />
          Zoom includes Labels&nbsp;
        </label> */}
      </div>
    </>
  )
}

function App() {
  const cameraRef = useRef()
  const controlsRef = useRef()

  const { setSceneCreated } = useStore()

  const initialCameraPosition = [28, -2, 22]

  return (
    <>
      <Canvas
        colorManagement={false}
        onCreated={() => {
          setSceneCreated(true)
          // would be better to get the extents of the scene and zoom to fit that
          controlsRef.current?.setPosition(initialCameraPosition[0], initialCameraPosition[1], initialCameraPosition[2], true)
          controlsRef.current?.setTarget(initialCameraPosition[0], initialCameraPosition[1], 0, true)
        }}>
        {/* todo: would be nice to be able to define cameras declaritively */}
        {/* <PerspectiveCamera ref={cameraRef} near={0.01}>
          <pointLight intensity={0.1} position={[0, 0, 0]} />
        </PerspectiveCamera> */}
        {/* <OrthographicCamera ref={cameraRef} near={0.01}>
          <pointLight intensity={0.1} position={[0, 0, 0]} />
        </OrthographicCamera> */}
        {/* <EffectComposer>
          <Bloom luminanceThreshold={0.7} luminanceSmoothing={0.7} height={300} />
          <Vignette eskil={false} offset={0.01} darkness={0.75} />
        </EffectComposer> */}
        <SceneNavigator controls={controlsRef.current} initialCameraPosition={initialCameraPosition} />
        <ProximityDetector disabled={false} />
        <ambientLight intensity={1} />
        <ExtendedCameraControls
          ref={controlsRef}
          camera={cameraRef.current}
          onWake={(e) => {
            // console.log('onWake')
            // animatingRef.current = true
            //setTimeout(() => {
            // if (!animating) {
            //   setAnimating(true)
            // }
            //}, 0)
          }}
          onSleep={(e) => {
            // console.log('onSleep')
            // animatingRef.current = false
            //setTimeout(() => {
            // if (animating) {
            //   setAnimating(false)
            // }
            //}, 0)
          }}
        />
        <HoverEffect>
          <LoadingMessage position={[1, 4.5, 0]} text="loading gltf">
            <GLTF
              src="https://cdn.glitch.com/84dfb089-aa8a-4831-a4a9-929164622393%2FAstronaut.glb"
              autoRotate
              position={[1, 3.5, 0]}
              scale={[2, 2, 2]}
            />
          </LoadingMessage>
        </HoverEffect>
        <HoverEffect>
          <LoadingMessage position={[7, 5.5, 0]} text="loading video">
            <Video
              src="https://cdn.glitch.com/84dfb089-aa8a-4831-a4a9-929164622393%2Fone-small-step.mp4"
              poster="/poster-one-small-step.png"
              position={[7, 5.5, 0]}
              scale={[7, 3.94, 0]}
            />
          </LoadingMessage>
        </HoverEffect>
        <LoadingMessage position={[0, 0, 0]} text="loading IIIF manifest">
          <IIIFManifest
            url="https://media.nga.gov/public/manifests/nga_highlights.json"
            position={[0, 0, 0]}
            scale={[0.025, 0.025, 0.025]}
            spacing={1.5}
            margin={[0.5, 0, 0.25, 0]}
          />
        </LoadingMessage>
        <LoadingMessage position={[0, -10, 0]} text="loading IIIF manifest">
          <IIIFManifest
            url="https://iiif.vam.ac.uk/collections/MSL:1876:Forster:141:II/manifest.json"
            position={[0, -10, 0]}
            scale={[0.0025, 0.0025, 0.0025]}
            spacing={1.5}
            margin={[0.5, 0, 0.25, 0]}
          />
        </LoadingMessage>
        {/* <LoadingMessage position={[0, -10, 0]} text="loading IIIF manifest">
          <IIIFManifest
            url="https://gallica.bnf.fr/iiif/ark:/12148/btv1b10509612q/manifest.json"
            position={[0, -10, 0]}
            scale={[0.0025, 0.0025, 0.0025]}
            spacing={1.5}
            margin={[0.5, 0, 0.25, 0]}
          />
        </LoadingMessage> */}
      </Canvas>
      <Header initialCameraPosition={initialCameraPosition} controls={controlsRef.current} />
    </>
  )
}

export default App
