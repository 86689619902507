import { useEffect, useState, useRef } from 'react'
import { loadManifest, parseManifest } from 'manifesto.js'
import { IIIFResourceType } from '@iiif/vocabulary'

export const useIIIFManifest = (url: string) => {
  const [manifest, setManifest] = useState()

  // https://www.npmjs.com/package/use-asset ?

  useEffect(() => {
    loadManifest(url)
      .then((json) => {
        const iiifResource: any = parseManifest(json)

        if (iiifResource && iiifResource.getIIIFResourceType() === IIIFResourceType.MANIFEST) {
          setManifest(iiifResource)
        } else {
          throw new Error('Unable to load IIIF manifest')
        }
      })
      .catch((err) => {
        throw new Error('Unable to load IIIF manifest')
      })
  }, [url])

  return manifest
}

export const useKeyPress = (targetKey: string, delay: number = 1000) => {
  const keypressInterval = useRef<any>()

  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false)
  // If pressed key is our target key then set to true
  function downHandler({ key }: { key: string }) {
    // console.log(key)
    if (key === targetKey) {
      if (!keypressInterval.current) {
        keypressInterval.current = setInterval(() => {
          setKeyPressed(false)
        }, delay)
      }
      setKeyPressed(true)
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }: { key: string }) => {
    if (key === targetKey) {
      clearInterval(keypressInterval.current)
      keypressInterval.current = undefined
      setKeyPressed(false)
    }
  }
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      clearInterval(keypressInterval.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}
