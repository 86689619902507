import create from 'zustand'

type State = {
  animating: boolean
  rotateDisabled: boolean
  selectedObject: string | null
  prevObject: string | null
  nextObject: string | null
  sceneCreated: boolean
  zoomIncludesLabels: boolean
}

const useStore = create<State>((set) => ({
  animating: false,
  rotateDisabled: true,
  selectedObject: null,
  prevObject: null,
  nextObject: null,
  sceneCreated: false,
  zoomIncludesLabels: false,

  setAnimating: (animating: boolean) => set({ animating }),
  setRotateDisabled: (rotateDisabled: boolean) =>
    set({
      rotateDisabled
    }),
  setSelectedObject: (id: string | null) =>
    set((state) => ({
      selectedObject: id,
      prevObject: id === null ? null : state.prevObject,
      nextObject: id === null ? null : state.nextObject,
    })),
  setPrevObject: (prevObject: string) =>
    set((state) => ({
      prevObject
    })),
  setNextObject: (nextObject: string) =>
    set((state) => ({
      nextObject
    })),
  setSceneCreated: (sceneCreated: boolean) =>
    set({
      sceneCreated
    }),
  setZoomIncludesLabels: (zoomIncludesLabels: boolean) =>
    set({
      zoomIncludesLabels
    })
}))

export default useStore
