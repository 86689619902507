import { Vec3 } from './Types'
import { useTexture } from '@react-three/drei'

export const Image = ({ src, position = [0, 0, 0], scale = [1, 1, 1] }: { src: string; position?: Vec3; scale?: Vec3 }) => {
  const texture = useTexture(src)
  return (
    <mesh position={position}>
      <planeBufferGeometry attach="geometry" args={[scale[0], scale[1]]} />
      <meshBasicMaterial toneMapped={false} map={texture} />
    </mesh>
  )
}
