import { useEffect, useState, useRef } from 'react'
import { Vec3 } from './Types'
import { Image } from './Image'
// @ts-ignore
import { a } from '@react-spring/three'
import { useAsset } from 'use-asset'

export const Video = ({
  src,
  position = [0, 0, 0],
  scale = [1, 1, 1],
  poster
}: {
  src: string
  position: Vec3
  scale: Vec3
  poster: string
}) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)

  const ref = useRef()

  const video = useAsset<any, any[]>(() => {
    return new Promise((resolve) => {
      const vid = document.createElement('video')
      vid.src = src
      vid.crossOrigin = 'Anonymous'
      vid.loop = true
      vid.oncanplay = () => {
        resolve(vid)
      }
    })
  })

  useEffect(() => {
    if (userInteracted) {
      if (videoPlaying) {
        video.play()
      } else {
        video.pause()
      }
    }
  }, [videoPlaying, video, userInteracted])

  return (
    <a.group
      ref={ref}
      position={position}
      onPointerDown={(e: any) => {
        e.stopPropagation()
        setUserInteracted(true)
        setVideoPlaying(!videoPlaying)
        // console.log('clicked ', ref.current)
      }}>
      <mesh>
        <planeBufferGeometry args={[scale[0], scale[1]]} />
        <meshBasicMaterial>
          <videoTexture attach="map" args={[video]} />
        </meshBasicMaterial>
      </mesh>
      {!userInteracted && <Image scale={scale} src={poster} />}
    </a.group>
  )
}
