// @ts-ignore
import { Object3D, Vector3 } from 'three'

export const waitFor = (
  cancelledRef: any,
  test: () => boolean,
  successCallback: () => void,
  failureCallback?: () => void,
  interval: number = 200,
  maxTries: number = 100,
  numTries: number = 0
) => {
  if (!cancelledRef.current) {
    numTries += 1
    let timeout: any

    if (numTries > maxTries) {
      if (failureCallback) failureCallback()
    } else if (test()) {
      console.log('successcallback')
      successCallback()
      clearTimeout(timeout)
    } else {
      timeout = setTimeout(function () {
        waitFor(cancelledRef, test, successCallback, failureCallback, interval, maxTries, numTries)
      }, interval)
    }
  }
}

export const truncate = (str: string, n: number, useWordBoundary?: boolean) => {
  if (str.length <= n) {
    return str
  }
  const subString = str.substr(0, n - 1) // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...'
}

export const truncateAtLineBreak = (str: string) => {
  const i = str.indexOf('\n')
  if (i !== -1) {
    return str.substr(0, i)
  }
  return str
}

export const arrToVec = (arr: number[]) => {
  return new Vector3().fromArray(arr)
}

const round = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const vecsEqual = (v1: Vector3, v2: Vector3, epsilon = Number.EPSILON) => {
  const v1x = round(v1.x)
  const v2x = round(v2.x)
  const v1y = round(v1.y)
  const v2y = round(v2.y)
  const v1z = round(v1.z)
  const v2z = round(v2.z)
  return Math.abs(v1x - v2x) < epsilon && Math.abs(v1y - v2y) < epsilon && Math.abs(v1z - v2z) < epsilon
}

export const normalize = (num: number, min: number, max: number) => {
  return (num - min) / (max - min)
}

export const getObjectByUserDataProperty = (object3d: Object3D, name: string, value: string) => {
  if (object3d.userData[name] === value) return object3d

  for (var i = 0, l = object3d.children.length; i < l; i++) {
    var child = object3d.children[i]
    var object: Object3D = getObjectByUserDataProperty(child, name, value)

    if (object !== undefined) {
      return object
    }
  }

  return undefined
}
